function achatController(
  $translate,
  $state,
  $scope,
  WS,
  $window,
  $parse,
  $compile,
  $timeout,
  DatatableLang
) {
  "ngInject";
  const vm = this;
  vm.searchbox = {};
  vm.chargement = {};
  vm.chargement.ch = {};
  vm.filter_stat = {};
  vm.chargement.ch.produits = {};
  vm.chargement.ch.remise_facture = 0;
  vm.chargement.ch.remise_espece = 0;
  vm.chargement.ch.remise_pied = 0;
  vm.codeparams = $state.params.code;

  vm.retenue = 0;
  vm.method_calcule = "cummulable";
  vm.remise_checkbox = false;


  vm.paiement = {};
  vm.paiement.cheques= [];
  vm.paiement.traites= [];

  vm.countBlockCheques = 1;
  vm.countBlockTraites = 1;

  vm.current_date = moment().format('DD/MM/YYYY');
  vm.document_print = {};

  
  vm.focused_index = 0;
  vm.focused_InputName = '';
  vm.focusedInput = (index, inputName) => {
    vm.focused_index = index;
    vm.focused_InputName = inputName;
    console.log(vm.focused_index , vm.focused_InputName)
  }

  vm.list_factures = [];
  vm.retenue_rs = {};
  vm.paiement_multiple={};
  vm.manageListFactures = (entete) => {
      if(vm.paiement_multiple[entete.code].selected==true) {
          vm.list_factures.push(entete);
      }else{
          vm.list_factures = vm.list_factures.filter( obj => obj.code !== entete.code);
      }
      console.log(vm.list_factures);

      vm.rest_a_payer = 0;
      vm.sum_montant_total_ttc = 0;
      vm.sum_total_ht = 0;
      vm.sum_sous_total_ht = 0;
      vm.sum_remise = 0;
      angular.forEach(vm.list_factures, function (value, key) {
        vm.rest_a_payer += value.net_a_payer ? Number(value.net_a_payer) : 0;
        vm.sum_montant_total_ttc += value.montant_total_ttc ? Number(value.montant_total_ttc) : 0;
        vm.sum_total_ht += value.total_ht ? Number(value.total_ht) : 0;
        vm.sum_sous_total_ht += value.sous_total_ht ? Number(value.sous_total_ht) : 0;
        vm.sum_remise += value.remise ? Number(value.remise) : 0;
      });
      vm.sum_retenue = 0;
      angular.forEach(vm.retenue_rs, function (value, key) {
        if(value.selected){
          vm.sum_retenue += value.montant_rs ? Number(value.montant_rs) : 0;
        }
      });

      vm.rest_a_payer = vm.rest_a_payer.toFixed(3);
      vm.rest_a_payer = Number(vm.rest_a_payer);

      vm.sum_retenue = vm.sum_retenue.toFixed(3);
      vm.sum_retenue = Number(vm.sum_retenue);
  }

  vm.retiterFacture = (entete) => {
    
    vm.list_factures = vm.list_factures.filter( obj => obj.code !== entete.code);
    vm.paiement_multiple[entete.code].selected=false;
    
    // console.log(vm.list_factures);

    vm.rest_a_payer = 0;
    vm.sum_montant_total_ttc = 0;
    vm.sum_total_ht = 0;
    vm.sum_sous_total_ht = 0;
    vm.sum_remise = 0;
    angular.forEach(vm.list_factures, function (value, key) {
      vm.rest_a_payer += value.net_a_payer ? Number(value.net_a_payer) : 0;
      vm.sum_montant_total_ttc += value.montant_total_ttc ? Number(value.montant_total_ttc) : 0;
      vm.sum_total_ht += value.total_ht ? Number(value.total_ht) : 0;
      vm.sum_sous_total_ht += value.sous_total_ht ? Number(value.sous_total_ht) : 0;
      vm.sum_remise += value.remise ? Number(value.remise) : 0;
    });
    vm.sum_retenue = 0;
    angular.forEach(vm.retenue_rs, function (value, key) {
      if(value.selected){
        vm.sum_retenue += value.montant_rs ? Number(value.montant_rs) : 0;
      }
    });

    vm.rest_a_payer = vm.rest_a_payer.toFixed(3);
    vm.rest_a_payer = Number(vm.rest_a_payer);

    vm.sum_retenue = vm.sum_retenue.toFixed(3);
    vm.sum_retenue = Number(vm.sum_retenue);
  };

  vm.recalculate_rs = (entete) => {
    if (vm.retenue_rs[entete.code].selected) {
      if (entete.fournisseur_code) {
        vm.retenue = _.findWhere(vm.select_frs, {
          code: entete.fournisseur_code,
        }).retenue;
      }
      vm.retenue_rs[entete.code].montant_rs = entete.montant_total_ttc * (vm.retenue / 100);
      // vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc - vm.chargement.ch.montant_rs;
    } else {
      vm.retenue_rs[entete.code].montant_rs = 0;
      //vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc;
    }

    // recalculate total after change rs
    vm.rest_a_payer = 0;
    vm.sum_montant_total_ttc = 0;
    vm.sum_total_ht = 0;
    vm.sum_sous_total_ht = 0;
    vm.sum_remise = 0;
    angular.forEach(vm.list_factures, function (value, key) {
      vm.rest_a_payer += value.net_a_payer ? Number(value.net_a_payer) : 0;
      vm.sum_montant_total_ttc += value.montant_total_ttc ? Number(value.montant_total_ttc) : 0;
      vm.sum_total_ht += value.total_ht ? Number(value.total_ht) : 0;
      vm.sum_sous_total_ht += value.sous_total_ht ? Number(value.sous_total_ht) : 0;
      vm.sum_remise += value.remise ? Number(value.remise) : 0;
    });
    vm.sum_retenue = 0;
    angular.forEach(vm.retenue_rs, function (value, key) {
      if(value.selected){
        vm.sum_retenue += value.montant_rs ? Number(value.montant_rs) : 0;
      }
    });

    vm.rest_a_payer = vm.rest_a_payer.toFixed(3);
    vm.rest_a_payer = Number(vm.rest_a_payer);

    //vm.sum_retenue
    vm.sum_retenue = vm.sum_retenue.toFixed(3);
    vm.sum_retenue = Number(vm.sum_retenue);

  };


  vm.getByPayement_code = (paiement_code) => {
    vm.searchbox.paiement_code=paiement_code;
    vm.searchbox.fournisseur_code="";
    vm.searchbox.date_debut="";
    vm.searchbox.date_fin="";
    vm.get();
  }

  $(document).ready(function() {
    $( document ).on( "keydown", function( event ) {
      var keyCode = event.which;
      //go to bottom
      if(keyCode==9 || keyCode==13 || keyCode==40){
        event.preventDefault();
        if(Object.keys(vm.chargement.ch.produits).length > vm.focused_index){
          vm.focused_index = vm.focused_index+1;
          var className = `.input-${vm.focused_InputName}-${vm.focused_index}`;
          console.log(vm.focused_index, className)
          $(className).focus(); 
        }
        
      }

      //go to top
      if(keyCode==38){
        event.preventDefault();
        if( vm.focused_index > 0){
          vm.focused_index = vm.focused_index - 1;
          var className = `.input-${vm.focused_InputName}-${vm.focused_index}`;
          $(className).focus(); 
        }
      }

      if(keyCode==39){
        event.preventDefault();
        switch (vm.focused_InputName) {
          case 'prix_achat_ht':
            vm.focused_InputName = 'prix_achat_ttc'
            break;
          case 'prix_achat_ttc':
            vm.focused_InputName = 'remise'
            break;
          case 'remise':
            vm.focused_InputName = 'remise_promo'
            break;
          case 'remise_promo':
            vm.focused_InputName = 'remise_dc'
            break;
          case 'remise_dc':
            vm.focused_InputName = 'quantite'
            break;
          case 'quantite':
            vm.focused_InputName = 'prix_achat_ht'
            break;
          default:
            console.log(`Sorry, we are out of ${vm.focused_InputName}.`);
        }
        var className = `.input-${vm.focused_InputName}-${vm.focused_index}`;
          $(className).focus(); 
      }

      if(keyCode==37){
        event.preventDefault();
        switch (vm.focused_InputName) {
          case 'prix_achat_ht':
            vm.focused_InputName = 'quantite'
            break;
          case 'prix_achat_ttc':
            vm.focused_InputName = 'prix_achat_ht'
            break;
          case 'remise':
            vm.focused_InputName = 'prix_achat_ttc'
            break;
          case 'remise_promo':
            vm.focused_InputName = 'remise'
            break;
          case 'remise_dc':
            vm.focused_InputName = 'remise_promo'
            break;
          case 'quantite':
            vm.focused_InputName = 'remise_dc'
            break;
          default:
            console.log(`Sorry, we are out of ${vm.focused_InputName}.`);
        }
        var className = `.input-${vm.focused_InputName}-${vm.focused_index}`;
          $(className).focus(); 
      }
      console.log( event.type + ": " +  event.which,  vm.focused_InputName);
      
    });
  })


  vm.changeRemise = (valeur_remise) => {
    // console.log(vm.remise_checkbox)
    if (vm.remise_checkbox) {
      _.each(vm.chargement["ch"]["produits"], (element, index) => {
        vm.chargement["ch"]["produits"][index].remise = valeur_remise;
      });
    }
  };
  vm.selectFournisseurs = function () {
    WS.get("fournisseurs/selectFournisseursWithCode")
      .then(function (response) {
        if (response.status == 200) {
          vm.select_frs = response.data;
          $scope.$apply();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.default_banque_id = "";


  vm.selectBanques = function () {
    WS.get("compte_banques/selectBanques")
      .then(function (response) {
        if (response.status == 200) {
          vm.select_banques = response.data;
          _.each(vm.select_banques, element => {
            if(element.principale== 1){

              vm.paiement.cheques[0] = {};
              vm.paiement.cheques[0].banque_id=element.id;
              vm.paiement.cheques[0].banque_cheque=element.banque;
              
              vm.paiement.traites[0] = {};

                vm.paiement.traites[0].banque_id=element.id;
                vm.paiement.traites[0].banque_traite=element.banque;
             


            }
          })

          $scope.$apply();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectedBanque = (index, banque_id, type) => {

    _.each(vm.select_banques, element => {
      if(element.id == banque_id){
        if(type=='cheque'){
          vm.paiement.cheques[index].banque_cheque = element.banque;
        }
        if(type=='traite'){
          vm.paiement.traites[index].banque_traite = element.banque;
        }
      }

    })
    
  }

  vm.total_des_paiements = 0;
  vm.getOldPaiement = () => {
      vm.total_des_paiements = 0;
      vm.sum_retenue = 0;
      if(vm.searchbox.paiement_code) {
        
        WS.getData("mouvement_fournisseurs/getOldPaiement", { paiement_code : vm.searchbox.paiement_code})
          .then(function (response) {
            if (response.status == 200) {
              vm.old_paiements = response.data;
              // console.log(vm.old_paiements);
              _.each(vm.old_paiements, (element, index) => {
                  vm.total_des_paiements += (element.type_montant == 'RS') ? -1 * element.montant : element.montant;
                  if(element.type_montant == 'RS'){
                    vm.retenue_rs[element.entete_achat_code].selected = true;
                    vm.retenue_rs[element.entete_achat_code].montant_rs = element.montant;
                    vm.sum_retenue += vm.retenue_rs[element.entete_achat_code].montant_rs ? Number(vm.retenue_rs[element.entete_achat_code].montant_rs) : 0;
                    
                  }
              });
              $scope.$apply();
            } else {
            }
          })
          .then(null, function (error) {
            console.log(error);
          });
      }
  }


  vm.get = function (page = 1, per_page = 15) {

    vm.p_sum_sous_total_ht = 0;
    vm.p_sum_total_ht = 0;
    vm.p_sum_total_ht = 0;
    vm.p_sum_montant_total_ttc = 0;
    vm.p_sum_montant_rs = 0;
    vm.p_sum_net_a_payer = 0;
    vm.p_sum_paiement = 0;

    var data = {
      per_page,
      page,
      // user_code: vm.searchbox.user_code,
      // depot_code: vm.searchbox.depot_code,
      date_debut: vm.searchbox.date_debut,
      date_fin: vm.searchbox.date_fin,
      orderby: vm.searchbox.orderby,
      paiement_code: vm.searchbox.paiement_code
    };
    WS.getData("entete_achat", data)
      .then(function (response) {
        if (response.status == 200) {
          vm.entetes = response.data.entete.data;
          vm.current_page = response.data.entete.current_page;
          vm.previous = vm.current_page - 1;
          vm.next = vm.current_page + 1;
          vm.from = response.data.entete.form;
          vm.last_page = response.data.entete.last_page;
          vm.next_page_url = response.data.entete.next_page_url;
          vm.per_page = response.data.entete.per_page.toString();
          vm.prev_page_url = response.data.entete.prev_page_url;
          vm.to = response.data.entete.to;
          vm.total = response.data.entete.total;
          vm.paginate = [];
          for (var i = 1; i <= vm.last_page; i++) {
            vm.paginate.push(i);
          }
          if(vm.searchbox.paiement_code){
            vm.list_factures = [];
            vm.p_sum_sous_total_ht = 0;
            vm.p_sum_total_ht = 0;
            vm.p_sum_total_ht = 0;
            vm.p_sum_montant_total_ttc = 0;
            vm.p_sum_montant_rs = 0;
            vm.p_sum_net_a_payer = 0;
            vm.p_sum_paiement = 0;
            _.each(vm.entetes, (element, index) => {
                vm.p_sum_sous_total_ht += element.sous_total_ht;
                vm.p_sum_total_ht += element.total_ht;
                vm.p_sum_total_ht += element.total_ht;
                vm.p_sum_montant_total_ttc += element.montant_total_ttc;
                vm.p_sum_montant_rs += element.montant_rs;
                vm.p_sum_net_a_payer += element.net_a_payer;

                //vm.list_factures.push(element);
                if(vm.paiement_multiple[element.code]){
                  vm.paiement_multiple[element.code].selected=true;
                  vm.paiement_multiple[element.code].disabled=true; 

                }else{
                  vm.paiement_multiple[element.code] = {};
                  vm.paiement_multiple[element.code].selected=true; 
                  vm.paiement_multiple[element.code].disabled=true; 
                }
                vm.manageListFactures(element);
                //vm.recalculate_rs(element);
            });
            vm.p_sum_paiement = response.data.total_paiement.sum_paiement;
          }
          $scope.$apply();

          vm.selectBanques();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.checkpaginate = function (current, page, lastpage) {
    if (page > current + 3 || page < current - 3) {
      return false;
    } else {
      return true;
    }
  };
  $(document).ready(function () {
    $(".sf_promo_gr_debut").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.searchbox.date_debut = date;
        $scope.$apply();
      },
    });

    $(".sf_promo_gr_fin").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.searchbox.date_fin = date;
        $scope.$apply();
      },
    });

    $(".f_date_debut").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.filter_stat.date_debut = date;
        $scope.$apply();
      },
    });

    $(".f_date_fin").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.filter_stat.date_fin = date;
        $scope.$apply();
      },
    });
  });
  $scope.moveCSV = function (element) {
    var reader = new FileReader();
    reader.onload = function (event) {
      vm.commande.file = event.target.result;
      $scope.$apply(function () {
        vm.files = element.files;
      });
    };
    reader.readAsDataURL(element.files[0]);
  };

  vm.storeEntete = function () {
    if (vm.files) {
      vm.commande.file = vm.files[0];
    } else {
      vm.commande.file = null;
    }
    WS.postwithimg("entete_achat/storeEntete", vm.commande, "POST").then(
      function (response) {
        if (response.data.success) {
          //vm.entetes.splice(0, 0, response.data.entete);
          $scope.$apply();
          swal(
            $translate.instant("Succés"),
            $translate.instant("Commande ajoutée avec succés"),
            "success"
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            $translate.instant("Attention"),
            $translate.instant(response.data.message),
            "warning"
          );
        }
      },
      function (error) {
        console.log(error);
        swal(
          $translate.instant("Attention"),
          $translate.instant("erreur d'insertion!"),
          "error"
        );
      }
    );
  };

  /**
   * *******************************************new page achat**********************************************
   */

  vm.auth_chargement = "1";
  vm.chargement_apply_filter = { chargement: vm.auth_chargement };
  vm.applyfilter = function () {
    if(vm.chargement.ch.type != 'avoir' && vm.chargement.ch.type != 'avoir_financier'){
        if (vm.auth_chargement == "0") {
          vm.chargement_apply_filter = "";
          if(vm.chargement.ch.fournisseur_code){
            vm.chargement_apply_filter = { fournisseur_code: (vm.chargement.ch.fournisseur_code) };
          }
        } else {
          vm.chargement_apply_filter = { chargement: vm.auth_chargement };
          if(vm.chargement.ch.fournisseur_code){
            vm.chargement_apply_filter = { chargement: vm.auth_chargement, fournisseur_code: (vm.chargement.ch.fournisseur_code) };
          }
          
        }
    }
    

  };

  vm.getproduits = function () {

      vm.chargement = {};
      vm.chargement.ch = {};
      vm.chargement.ch.produits = {};
      vm.chargement.ch.remise_facture = 0;
      vm.chargement.ch.remise_espece = 0;
      vm.chargement.ch.remise_pied = 0;
      vm.codeparams = $state.params.code;
      vm.btn_save = false;
    WS.get("mouvements/names")
      .then(function (response) {
        vm.chargement.ch.entete_achat_code = $state.params.code;
        vm.chargement.ch.code = $state.params.code;
        vm.produits = response.data.produits;
        vm.loading = false;
        vm.chargement.ch.remise_facture = 0;
        vm.chargement.ch.remise_espece = 0;
        vm.chargement.ch.remise_pied = 0;
        vm.btn_save =false;
        // traitement
        _.each(vm.produits, (element, index) => {
          vm.chargement["ch"]["produits"][element.id] = {};
          vm.chargement["ch"]["produits"][element.id]["id"] = element.id;
          vm.chargement["ch"]["produits"][element.id]["code"] = element.code;
          vm.chargement["ch"]["produits"][element.id]["produit_code"] =
            element.code;
          vm.chargement["ch"]["produits"][element.id]["code_a_barre"] =
            element.code_a_barre;
          vm.chargement["ch"]["produits"][element.id]["libelle"] =
            element.libelle;
          vm.chargement["ch"]["produits"][element.id]["colisage"] =
            element.colisage;
          vm.chargement["ch"]["produits"][element.id]["tva"] = element.tva;
          vm.chargement["ch"]["produits"][element.id]["prix_achat_ht"] =
            element.prix_achat_ht;
          vm.chargement["ch"]["produits"][element.id]["prix_achat_ttc"] =
            element.prix_achat_ttc;
          vm.chargement["ch"]["produits"][element.id]["prix_ttc"] =
            element.prix_ttc;
          vm.chargement["ch"]["produits"][element.id]["prix_ht"] =
            element.prix_ht;

          vm.chargement["ch"]["produits"][element.id]["ordre"] = element.ordre;
          vm.chargement["ch"]["produits"][element.id]["chargement"] =
            element.chargement;
          vm.chargement["ch"]["produits"][element.id]["sousfamille_code"] =
            element.sousfamille_code;
          vm.chargement["ch"]["produits"][element.id]["fournisseur_code"] =
            element.fournisseur_code;
          vm.chargement["ch"]["produits"][element.id]["famille_code"] =
            element.famille_code;
          vm.chargement["ch"]["produits"][element.id]["gamme_code"] =
            element.gamme_code;
          vm.chargement["ch"]["produits"][element.id]["stk_quantite"] =
            element.stk_quantite;
          vm.chargement["ch"]["produits"][element.id]["stk_casse"] =
            element.stk_casse;
          vm.chargement["ch"]["produits"][element.id]["stk_dlc"] =
            element.stk_dlc;
          vm.chargement["ch"]["produits"][element.id]["hors_box"] =
            element.hors_box;
          //remises
          vm.chargement["ch"]["produits"][element.id]["remise"] = 0;
          vm.chargement["ch"]["produits"][element.id]["remise_promo"] = 0;
          vm.chargement["ch"]["produits"][element.id]["remise_dc"] = 0;
          vm.chargement["ch"]["produits"][element.id]["remise_1"] = null;
          vm.chargement["ch"]["produits"][element.id]["remise_2"] = null;
          vm.chargement["ch"]["produits"][element.id]["remise_3"] = null;

          vm.chargement["ch"]["produits"][element.id]["dlc"] = null;
          vm.chargement["ch"]["produits"][element.id]["num_lot"] = null;

          vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ht"] =
            vm.chargement["ch"]["produits"][element.id]["prix_achat_ht"] -
            (vm.chargement["ch"]["produits"][element.id]["prix_achat_ht"] *
              (Number(vm.chargement["ch"]["produits"][element.id]["remise"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_promo"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_dc"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_1"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_2"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_3"]) +
                Number(vm.chargement.ch.remise_facture) +
                Number(vm.chargement.ch.remise_espece) +
                Number(vm.chargement.ch.remise_pied))) / 100;
          vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ttc"] =
            vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ht"] +
            (vm.chargement["ch"]["produits"][element.id]["tva"] / 100) *
              vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ht"];

          vm.chargement["ch"]["produits"][element.id]["total_net_ht"] = null;
          vm.chargement["ch"]["produits"][element.id]["total_net_ttc"] = null;
          vm.chargement["ch"]["produits"][element.id]["sous_total_ht"] = null;

          // console.log('-------------------------------------------------------------------------------')
          // console.log(vm.chargement['ch']['produits'][element.id]);
        });

        $scope.$apply();
        if ($state.params.code) {
          vm.getCurrentAchat(); //to get old quantites stored;
        }else{
          // $('.table').DataTable({
          //     "language": DatatableLang,
          //     "paging": false,
          //     'searching' : false
          // });
        }
        
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.changeValue = (id) => {
    //console.log('started function changeValue calcul total remise net', vm.method_calcule, id,vm.chargement['ch']['produits'][id] );
    if (vm.method_calcule == "cummulable") {
      //prix_achat_net_ht=prix_achat_brut-
      //    (prix_achat_brut*((remise+remise_promo+ remise_1+remise_2+remise_3+remise_facture+remise_espece+remise_pied))/100))-
      vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"] =
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) -
        (Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) *
          (Number(vm.chargement["ch"]["produits"][id]["remise"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_promo"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_dc"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_1"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_2"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_3"]) +
            Number(vm.chargement.ch.remise_facture) +
            Number(vm.chargement.ch.remise_espece) +
            Number(vm.chargement.ch.remise_pied))) /
          100;
    } else {
      //prix_achat_net_ht_fixe=prix_achat_brut- (prix_achat_brut*((remise+remise_promo+ remise_1+remise_2+remise_3/100))
      //prix_achat_net_ht= prix_achat_net_ht_fixe- (prix_achat_net_ht_fixe*((remise_facture+remise_espece+remise_pied))/100))-
      vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"] =
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) -
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) *
          ((Number(vm.chargement["ch"]["produits"][id]["remise"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_promo"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_dc"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_1"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_2"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_3"])) /
            100);

      vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"] =
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) -
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) *
          ((vm.chargement.ch.remise_facture +
            vm.chargement.ch.remise_espece +
            vm.chargement.ch.remise_pied) /
            100);
    }

    vm.chargement["ch"]["produits"][id]["prix_achat_net_ttc"] =
            Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) +
            (Number(vm.chargement["ch"]["produits"][id]["tva"]) / 100) *
              Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]);

    if(vm.chargement.ch.colisage==false) {
          

          vm.chargement["ch"]["produits"][id]["total_net_ht"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
            ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"])
            : null;
          vm.chargement["ch"]["produits"][id]["total_net_ttc"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
            ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ttc"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"])
            : null;

          /**
           * sous_total_ht
           */

          vm.chargement["ch"]["produits"][id]["sous_total_ht"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
            ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"])
            : null;

          /**
           * remise
           */
          vm.chargement["ch"]["produits"][id]["montant_remise"] =
            vm.chargement["ch"]["produits"][id]["sous_total_ht"] -
            vm.chargement["ch"]["produits"][id]["total_net_ht"];

          //calcul sum_net_ttc et sum_net_ht ,sum_ttc, sum_ht
          vm.calculeTotal();
    }else{
      vm.chargement["ch"]["produits"][id]["total_net_ht"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
        ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"]) * Number(vm.chargement["ch"]["produits"][id]["colisage"])
        : null;
      vm.chargement["ch"]["produits"][id]["total_net_ttc"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
        ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ttc"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"]) * Number(vm.chargement["ch"]["produits"][id]["colisage"])
        : null;

      /**
       * sous_total_ht
       */

      vm.chargement["ch"]["produits"][id]["sous_total_ht"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
        ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"]) * Number(vm.chargement["ch"]["produits"][id]["colisage"])
        : null;

      /**
       * remise
       */
      vm.chargement["ch"]["produits"][id]["montant_remise"] =
        vm.chargement["ch"]["produits"][id]["sous_total_ht"] -
        vm.chargement["ch"]["produits"][id]["total_net_ht"];

      //calcul sum_net_ttc et sum_net_ht ,sum_ttc, sum_ht
      vm.calculeTotal();
    }

    if(vm.chargement["ch"]["produits"][id]["code"] == 'CAISSE' || 'PALETTE' || 'ga'){
      vm.getCaissePaletteId();
    }
  };

  vm.getCaissePaletteId = () => {

    /**
     * get caisses
     */
    var caisse = _.find(vm.produits, function(item) { //\\
        return (item.code == 'CAISSE' || item.code.toLowerCase() == 'ga'); //  \\
    }); //    \\
    if(caisse){
        vm.nbr_caisses = vm.chargement.ch.produits[caisse.id].quantite;
    }

    /**
     * get Palette
     */
     var palette = _.find(vm.produits, function(item) { //\\
      return (item.code == 'PALETTE'); //  \\
    }); //    \\
    if(palette){
        vm.nbr_palettes = vm.chargement.ch.produits[palette.id].quantite;
    }

  }

  vm.updateCaissePalette = (code) => {

    if(code == 'CAISSE'){
      var caisse = _.find(vm.produits, function(item) { //\\
        return (item.code == 'CAISSE' || item.code.toLowerCase() == 'ga'); //  \\
      }); //    \\
      if(caisse){
          vm.chargement.ch.produits[caisse.id].quantite = vm.nbr_caisses;
      }
    }

    if( code == 'PALETTE') {
      var palette = _.find(vm.produits, function(item) { //\\
        return (item.code == 'PALETTE'); //  \\
      }); //    \\
      if(palette){
           vm.chargement.ch.produits[palette.id].quantite = vm.nbr_palettes;
      }
    }
  }

  vm.calculeTotal = () => {
    //console.log('obj', vm.chargement['ch']['produits'])

    vm.chargement.ch.montant_total_ttc =
      _.reduce(
        vm.chargement["ch"]["produits"],
        function (memo, num) {
          return memo + num.total_net_ttc;
        },
        0
      ) + vm.chargement.ch.timbre;
    vm.chargement.ch.total_ht = _.reduce(
      vm.chargement["ch"]["produits"],
      function (memo, num) {
        return memo + num.total_net_ht;
      },
      0
    );

    vm.chargement.ch.total_tva =
      vm.chargement.ch.montant_total_ttc -
      vm.chargement.ch.timbre -
      vm.chargement.ch.total_ht;

    // sous_total_ht
    vm.chargement.ch.sous_total_ht = _.reduce(
      vm.chargement["ch"]["produits"],
      function (memo, num) {
        return memo + num.sous_total_ht;
      },
      0
    );
    vm.chargement.ch.remise =
      vm.chargement.ch.sous_total_ht - vm.chargement.ch.total_ht;

    // retenue & net_a_payer
    if (vm.chargement.ch.calcule_rs) {
      if (vm.chargement.ch.fournisseur_code) {
        vm.retenue = _.findWhere(vm.select_frs, {
          code: vm.chargement.ch.fournisseur_code,
        }).retenue;
      }
      vm.chargement.ch.montant_rs =
        vm.chargement.ch.montant_total_ttc * (vm.retenue / 100);
      vm.chargement.ch.net_a_payer =
        vm.chargement.ch.montant_total_ttc - vm.chargement.ch.montant_rs;
    } else {
      vm.chargement.ch.montant_rs = 0;
      vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc;
    }



    //calcule nbr reference nbr caisses nbr palette //
    vm.calculeRef();
  };

  vm.calculeRef = () => {
    //vm.nbr_caisses = 0;
    vm.nbr_references = 0;
   // vm.nbr_palettes = 0;

    _.each(vm.chargement.ch.produits, (element, index) => {

        var quantite = Number(element.quantite);

        if(vm.chargement.ch.colisage==false){
          //vm.nbr_caisses += _.isNumber(quantite) && !_.isNaN(quantite) ? Number(quantite) / Number(element.colisage) : 0;
          vm.nbr_references += _.isNumber(quantite)&& !_.isNaN(quantite) ? 1 :0 ;
        }else{
          //vm.nbr_caisses += _.isNumber(quantite) && !_.isNaN(quantite) ? Number(quantite) : 0;
          vm.nbr_references += _.isNumber(quantite)&& !_.isNaN(quantite) ? 1 :0 ;
        }
        
    });
    //vm.nbr_caisses = vm.nbr_caisses.toFixed(0);
    
  }

  $scope.$watch("vm.method_calcule", function (value) {
    _.each(vm.chargement["ch"]["produits"], (element) =>
      vm.changeValue(element.id)
    );
  });


  $scope.$watch("vm.chargement.ch.colisage", function (value) {
    
    _.each(vm.chargement["ch"]["produits"], (element) => {
      vm.changeValue(element.id)
      vm.calculeRef()
    });
  });

  $scope.$watch("vm.chargement.ch.remise_facture", function (value) {
    console.log(
      "vm.chargement.ch.remise_facture",
      vm.chargement.ch.remise_facture
    );
    _.each(vm.chargement["ch"]["produits"], (element) =>
      vm.changeValue(element.id)
    );
  });
  $scope.$watch("vm.chargement.ch.remise_espece", function (value) {
    console.log(
      "vm.chargement.ch.remise_espece",
      vm.chargement.ch.remise_espece
    );
    _.each(vm.chargement["ch"]["produits"], (element) =>
      vm.changeValue(element.id)
    );
  });
  $scope.$watch("vm.chargement.ch.remise_pied", function (value) {
    console.log(
      "vm.chargement.ch.remise_pied",
      value,
      vm.chargement.ch.remise_pied
    );
    _.each(vm.chargement["ch"]["produits"], (element) =>
      vm.changeValue(element.id)
    );
  });

  // vm.select_frs

  $scope.$watch("vm.chargement.ch.fournisseur_code", function (value) {
    if (vm.chargement.ch.calcule_rs) {
      if (vm.chargement.ch.fournisseur_code) {
        vm.retenue = _.findWhere(vm.select_frs, {
          code: vm.chargement.ch.fournisseur_code,
        }).retenue;
      }
      vm.chargement.ch.montant_rs =
        vm.chargement.ch.montant_total_ttc * (vm.retenue / 100);
      vm.chargement.ch.net_a_payer =
        vm.chargement.ch.montant_total_ttc - vm.chargement.ch.montant_rs;
    } else {
      vm.chargement.ch.montant_rs = 0;
      vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc;
    }
  });

  vm.recalculateRs = () => {
    if (vm.chargement.ch.calcule_rs) {
      if (vm.chargement.ch.fournisseur_code) {
        vm.retenue = _.findWhere(vm.select_frs, {
          code: vm.chargement.ch.fournisseur_code,
        }).retenue;
      }
      vm.chargement.ch.montant_rs =
        vm.chargement.ch.montant_total_ttc * (vm.retenue / 100);
      vm.chargement.ch.net_a_payer =
        vm.chargement.ch.montant_total_ttc - vm.chargement.ch.montant_rs;
    } else {
      vm.chargement.ch.montant_rs = 0;
      vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc;
    }
  };

  

  $scope.$watch("vm.chargement.ch.soussociete_code", function (value) {
    if (vm.chargement.ch.soussociete_code) {
      vm.chargement.ch.timbre = _.findWhere(vm.soussocietes, {
        code: vm.chargement.ch.soussociete_code,
      }).timbre;
    }
  });

  vm.addLigne = (id) => {
    console.log(id);
    const tr_id = `tr_prod_${id}`;
    const td_id = `td_prod_${id}`;
  };

  vm.getCurrentAchat = function () {
    WS.getData("entete_achat/getCurrentAchat", { code: $state.params.code })
      .then(function (response) {
        if (response.data.length > 0) {
          _.each(vm.chargement["ch"]["produits"], (element, index) => {
            console.log(element);
            response.data.forEach((ligne) => {
              if (ligne.produit_code == element.produit_code) {
                vm.chargement["ch"]["produits"][index]["quantite"] = ligne.quantite;

                vm.chargement["ch"]["produits"][index]["prix_achat_ht"] = ligne.prix_achat_ht;
                vm.chargement["ch"]["produits"][index]["prix_achat_ttc"] = ligne.prix_achat_ttc;
                vm.chargement["ch"]["produits"][index]["prix_ht"] = ligne.prix_ht;
                vm.chargement["ch"]["produits"][index]["prix_ttc"] =  ligne.prix_ttc;

                vm.chargement["ch"]["produits"][index]["prix_achat_net_ht"] = ligne.prix_achat_net_ht;
                vm.chargement["ch"]["produits"][index]["prix_achat_net_ttc"] = ligne.prix_achat_net_ttc;

                vm.chargement["ch"]["produits"][index]["total_net_ht"] = ligne.total_net_ht;
                vm.chargement["ch"]["produits"][index]["total_net_ttc"] = ligne.total_net_ttc;

                vm.chargement["ch"]["produits"][index]["remise"] = ligne.remise;
                vm.chargement["ch"]["produits"][index]["remise_promo"] = ligne.remise_promo;
                vm.chargement["ch"]["produits"][index]["remise_dc"] = ligne.remise_dc;
                vm.chargement["ch"]["produits"][index]["remise_1"] = ligne.remise_1;
                vm.chargement["ch"]["produits"][index]["remise_2"] = ligne.remise_2;
                vm.chargement["ch"]["produits"][index]["remise_3"] = ligne.remise_3;

                vm.chargement["ch"]["produits"][index]["tva"] = ligne.tva;

                vm.chargement["ch"]["produits"][index]["dlc"] = ligne.dlc;
                vm.chargement["ch"]["produits"][index]["num_lot"] = ligne.num_lot;

                vm.chargement["ch"]["produits"][index]["sous_total_ht"] = ligne.quantite * ligne.prix_achat_ht;

                //vm.changeValue(id)
              }
            });
          });
          vm.calculeTotal();

        }
        $scope.$apply();
        // $('.table').DataTable({
        //     "language": DatatableLang,
        //     "paging": false,
        //     'searching' : false
        // });
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getGammes = function () {
    WS.get("gammes")
      .then(function (response) {
        vm.gammes = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getFamilles = function () {
    WS.get("familles")
      .then(function (response) {
        vm.familles = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getSousfamilles = function () {
    WS.get("sousfamilles")
      .then(function (response) {
        vm.sousfamilles = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.storeLigne_achat = function (chargement) {
    vm.btn_save = true;
    vm.show_loader = true;
    var data = {};
    data.produits = { produits: [] };

    _.each(chargement.ch.produits, (value, key) => {
      if (value.quantite != undefined && value.quantite != null) {
        data.produits.produits.push(value);
      }
    });

    
    data.entete_achat_code = chargement.ch.entete_achat_code;
    data.colisage = chargement.ch.colisage;
    data.type = chargement.ch.type;

    data.id = vm.chargement["ch"]["id"];
    data.code = vm.chargement["ch"]["code"];
    data.fournisseur_code = vm.chargement["ch"]["fournisseur_code"];
    data.frs_nom = vm.chargement["ch"]["frs_nom"];
    data.frs_mobile = vm.chargement["ch"]["frs_mobile"];
    data.frs_email = vm.chargement["ch"]["frs_email"];
    data.societe_code = vm.chargement["ch"]["societe_code"];
    data.soussociete_code = vm.chargement["ch"]["soussociete_code"];
    data.type = vm.chargement["ch"]["type"];
    data.document_type = vm.chargement["ch"]["document_type"];
    data.total_ht_ligne = vm.chargement["ch"]["total_ht_ligne"];
    data.remise_facture = vm.chargement["ch"]["remise_facture"];
    data.remise_espece = vm.chargement["ch"]["remise_espece"];
    data.remise_pied = vm.chargement["ch"]["remise_pied"];
    data.total_tva = vm.chargement["ch"]["total_tva"];
    data.timbre = vm.chargement["ch"]["timbre"];
    data.montant_rs = vm.chargement["ch"]["montant_rs"];
    data.retenue_1 = vm.chargement["ch"]["retenue_1"];
    data.retenue_2 = vm.chargement["ch"]["retenue_2"];
    data.retenue_3 = vm.chargement["ch"]["retenue_3"];

    data.sous_total_ht = (vm.chargement["ch"]["type"] == 'avoir' ||  vm.chargement["ch"]["type"] =='avoir_financier') ? vm.chargement["ch"]["sous_total_ht"] * -1 : vm.chargement["ch"]["net_a_payer"] ;
    data.total_ht = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] =='avoir_financier') ? vm.chargement["ch"]["total_ht"] * -1 : vm.chargement["ch"]["net_a_payer"] ;
    data.montant_total_ttc = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] == 'avoir_financier') ? vm.chargement["ch"]["montant_total_ttc"] * -1 : vm.chargement["ch"]["net_a_payer"] ;
    data.net_a_payer = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] =='avoir_financier') ? vm.chargement["ch"]["net_a_payer"] * -1 : vm.chargement["ch"]["net_a_payer"] ;


    data.commentaire = vm.chargement["ch"]["commentaire"];
    data.anomalie = vm.chargement["ch"]["anomalie"];
    data.transporteur_code = vm.chargement["ch"]["transporteur_code"];
    data.transporteur_libelle = vm.chargement["ch"]["transporteur_libelle"];
    data.chauffeur = vm.chargement["ch"]["chauffeur"];
    data.matricule = vm.chargement["ch"]["matricule"];
    data.user_code = vm.chargement["ch"]["user_code"];
    data.code_commande = vm.chargement["ch"]["code_commande"];
    data.document_code = vm.chargement["ch"]["document_code"];
    data.date_document = vm.chargement["ch"]["date_document"];
    data.remise = vm.chargement["ch"]["remise"];
    data.tva = vm.chargement["ch"]["tva"];
    data.cloture = vm.chargement["ch"]["cloture"];

    console.log(data);

    var nbr_error =0;
    vm.message_error = ''

    // if(data.produits.produits.length == 0){
    //   nbr_error = nbr_error + 1
    //   vm.message_error = 'Veuillez selectionner au moins un produit'
    // }

    if(data.fournisseur_code =="" || data.fournisseur_code == undefined){
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le fournisseur'
    }
    if(data.soussociete_code =="" || data.soussociete_code == undefined){
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le disitributeur'
    } 


    if(data.document_type =="" || data.document_type == undefined){
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le Document'
    } 
    if(data.type =="" || data.type == undefined){
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le type'
    } 
    if(data.date_document =="" || data.date_document == undefined){
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner la date'
    } 
    if(data.code =="" || data.code==undefined)    {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le code de facture'
    }
    

    if(nbr_error == 0) {
      WS.post("entete_achat/storeLigne", data)
      .then(function (response) {
        if (response.data.success) {
          swal(
            $translate.instant("Succès"),
            $translate.instant("chargement ajoutée avec Succès"),
            "success"
          )
            .then(function () {
              vm.btn_save = false;
              vm.show_loader = false;

              $state.go('app.achat.details', {code : response.data.code}, {
                  reload: false,
                  inherit: false
              });
              vm.show_loader = false;

              //$window.location.reload();
            })
            .catch(function (error) {
              vm.btn_save = false;
              vm.show_loader = false;
              $state.go('app.achat.details', {code : response.data.code}, {
                  reload: false,
                  inherit: false
              });
              vm.show_loader = false;
              $scope.$apply();
              //$window.location.reload();
            });
        }
        vm.btn_save = false;
        vm.show_loader = false;
        $scope.$apply();
      })
      .then(null, function (error) {
        vm.btn_save = false;
        vm.show_loader = false;
        $scope.$apply();
        console.log(error);
      });
    }else{
      vm.btn_save = false;
      vm.show_loader = false;
      swal(
        $translate.instant(`Oups`),
        $translate.instant(vm.message_error),
        "warning"
      )
    }
    
  };

  vm.convertirEnBox = function (chargement) {
    if (vm.c_box == true) {
      _.each(chargement.ch.produits, (value, key) => {
        if (value instanceof Object) {
          vm.chargement["ch"]["produits"][key]["quantite"] =
            Number(vm.chargement["ch"]["produits"][key]["quantite"]) /
            Number(value.colisage);
        }
      });
      // cocher colisage
    } else {
      _.each(chargement.ch.produits, (value, key) => {
        if (value instanceof Object) {
          vm.chargement["ch"]["produits"][key]["quantite"] =
            Number(vm.chargement["ch"]["produits"][key]["quantite"]) *
            Number(value.colisage);
        }
      });
      // decocher colisage
    }
    if (vm.c_box == false) {
      vm.c_box_title = "En Box";
    } else {
      vm.c_box_title = "En piéce";
    }
    vm.chargement["ch"]["colisage"] = vm.c_box;
    vm.c_box = !vm.c_box;
  };

  vm.findEnteteByCode = function () {
    if($state.params.code){
        vm.update_code_achat = true

        WS.getData("entete_achat/findEnteteByCode", { code: $state.params.code })
        .then(function (response) {
            vm.entete_achat = response.data;
            console.log(vm.entete_achat);
            if (vm.entete_achat) {
            vm.chargement["ch"]["id"] = vm.entete_achat.id;
            vm.chargement["ch"]["code"] = vm.entete_achat.code;
            vm.chargement["ch"]["fournisseur_code"] =
                vm.entete_achat.fournisseur_code;
            vm.chargement["ch"]["frs_nom"] = vm.entete_achat.frs_nom;
            vm.chargement["ch"]["frs_mobile"] = vm.entete_achat.frs_mobile;
            vm.chargement["ch"]["frs_email"] = vm.entete_achat.frs_email;
            vm.chargement["ch"]["societe_code"] = vm.entete_achat.societe_code;
            vm.chargement["ch"]["soussociete_code"] =
                vm.entete_achat.soussociete_code;
            vm.chargement["ch"]["type"] = vm.entete_achat.type;
            vm.chargement["ch"]["document_type"] = vm.entete_achat.document_type;
            vm.chargement["ch"]["total_ht_ligne"] =
                vm.entete_achat.total_ht_ligne;
            vm.chargement["ch"]["sous_total_ht"] = vm.entete_achat.sous_total_ht;
            vm.chargement["ch"]["remise_facture"] =
                vm.entete_achat.remise_facture;
            vm.chargement["ch"]["remise_espece"] = vm.entete_achat.remise_espece;
            vm.chargement["ch"]["remise_pied"] = vm.entete_achat.remise_pied;
            vm.chargement["ch"]["total_ht"] = vm.entete_achat.total_ht;
            vm.chargement["ch"]["total_tva"] = vm.entete_achat.total_tva;
            vm.chargement["ch"]["montant_total_ttc"] =
                vm.entete_achat.montant_total_ttc;
            vm.chargement["ch"]["timbre"] = vm.entete_achat.timbre;
            vm.chargement["ch"]["montant_rs"] = vm.entete_achat.montant_rs;
            vm.chargement["ch"]["retenue_1"] = vm.entete_achat.retenue_1;
            vm.chargement["ch"]["retenue_2"] = vm.entete_achat.retenue_2;
            vm.chargement["ch"]["retenue_3"] = vm.entete_achat.retenue_3;
            vm.chargement["ch"]["net_a_payer"] = vm.entete_achat.net_a_payer;
            vm.chargement["ch"]["commentaire"] = vm.entete_achat.commentaire;
            vm.chargement["ch"]["anomalie"] = vm.entete_achat.anomalie;
            vm.chargement["ch"]["transporteur_code"] =
                vm.entete_achat.transporteur_code;
            vm.chargement["ch"]["transporteur_libelle"] =
                vm.entete_achat.transporteur_libelle;
            vm.chargement["ch"]["chauffeur"] = vm.entete_achat.chauffeur;
            vm.chargement["ch"]["matricule"] = vm.entete_achat.matricule;
            vm.chargement["ch"]["user_code"] = vm.entete_achat.user_code;
            vm.chargement["ch"]["date"] = vm.entete_achat.date;
            vm.chargement["ch"]["code_commande"] = vm.entete_achat.code_commande;
            vm.chargement["ch"]["document_code"] = vm.entete_achat.document_code;
            vm.chargement["ch"]["date_document"] = vm.entete_achat.date_document;
            vm.chargement["ch"]["remise"] = vm.entete_achat.remise;
            vm.chargement["ch"]["tva"] = vm.entete_achat.tva;
            vm.chargement["ch"]["cloture"] = vm.entete_achat.cloture;
            } else {
            //   vm.entete_achat.cloture = 0;

            //   vm.chargement["ch"]["id"] = null;
            //   vm.chargement["ch"]["code"] = "";
            //   vm.chargement["ch"]["fournisseur_code"] = "";
            //   vm.chargement["ch"]["frs_nom"] = "";
            //   vm.chargement["ch"]["frs_mobile"] = "";
            //   vm.chargement["ch"]["frs_email"] = "";
            //   vm.chargement["ch"]["societe_code"] = "";
            //   vm.chargement["ch"]["soussociete_code"] = "";
            //   vm.chargement["ch"]["type"] = "";
            //   vm.chargement["ch"]["document_type"] = "";
            //   vm.chargement["ch"]["total_ht_ligne"] = null;
            //   vm.chargement["ch"]["sous_total_ht"] = null;
            //   vm.chargement["ch"]["remise_facture"] = null;
            //   vm.chargement["ch"]["remise_espece"] = null;
            //   vm.chargement["ch"]["remise_pied"] = null;
            //   vm.chargement["ch"]["total_ht"] = null;
            //   vm.chargement["ch"]["total_tva"] = null;
            //   vm.chargement["ch"]["montant_total_ttc"] = null;
            //   vm.chargement["ch"]["timbre"] = null;
            //   vm.chargement["ch"]["montant_rs"] = null;
            //   vm.chargement["ch"]["retenue_1"] = null;
            //   vm.chargement["ch"]["retenue_2"] = null;
            //   vm.chargement["ch"]["retenue_3"] = null;
            //   vm.chargement["ch"]["net_a_payer"] = null;
            //   vm.chargement["ch"]["commentaire"] = "";
            //   vm.chargement["ch"]["anomalie"] = "";
            //   vm.chargement["ch"]["transporteur_code"] = "";
            //   vm.chargement["ch"]["transporteur_libelle"] = "";
            //   vm.chargement["ch"]["chauffeur"] = "";
            //   vm.chargement["ch"]["matricule"] = "";
            //   vm.chargement["ch"]["user_code"] = "";
            //   vm.chargement["ch"]["date"] = "";
            //   vm.chargement["ch"]["code_commande"] = "";
            //   vm.chargement["ch"]["document_code"] = "";
            //   vm.chargement["ch"]["date_document"] = "";
            //   vm.chargement["ch"]["remise"] = null;
            //   vm.chargement["ch"]["tva"] = null;
            //   vm.chargement["ch"]["cloture"] = null;
            }
            console.log(
            "----------------------test cloture ------------------------"
            );
            console.log(vm.entete_achat.cloture, vm.entete_achat);
            $scope.$apply();
        })
        .then(null, function (error) {
            console.log(error);
        });
    }else{
      vm.update_code_achat = false
    }
  };

  vm.clotureEntete_achat = function (code) {
    swal({
      title: $translate.instant("Cloture"),
      text: $translate.instant("Valider l'achat de") + " : " + code,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: $translate.instant("Oui"),
      cancelButtonText: $translate.instant("Non"),
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: true,
    })
      .then(function () {
        WS.put("entete_achat/clotureEntete_achat/" + code, { cloture: 1 })
          .then(function (response) {
            if (response.data.success == true) {
              swal(
                $translate.instant(`Succé`),
                $translate.instant(`Achat Ajouté avec succé`),
                "success"
              ).then(function () {
                $window.location.reload();
              });
            }
          })
          .then(null, function (error) {
            console.log(error);
          });
      })
      .catch(function () {
        console.log("cancelled!");
      });
  };

  vm.getStockDepotByType = function (type, soussociete_code) {
    WS.getData("stock_depot/getStockDepotByType", { soussociete_code, type })
      .then(function (response) {
        _.each(vm.chargement.ch.produits, (value, key) => {
          if (value instanceof Object) {
            _.each(response.data, (s_value, s_key) => {
              if (s_value.produit_code == value.produit_code) {
                vm.chargement["ch"]["produits"][key]["quantite"] = Number(
                  s_value[type]
                );
              }
            });
          }
        });
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.exportAchat = () => {
    WS.getData("entete_achat/exportEnteteAchat", { code: $state.params.code })
      .then(function (response) {
        if (response.data.length > 0) {
          var filename =
            response.data[0].type +
            "-" +
            response.data[0].code +
            "-" +
            response.data[0].date;
          var file = {
            sheetid: filename,
            headers: true,
            caption: {
              title: filename,
              style: "font-size: 50px; color:blue;",
            },
            column: {
              style: "font-size:20px;background:#d1d1d1;",
            },
            columns: [
              { columnid: "code", title: "Code" },
              { columnid: "date", title: "Date" },
              { columnid: "ru", title: "Retour Usine" },
              { columnid: "type", title: "Type" },
              { columnid: "cloture", title: "Cloture" },
              { columnid: "user", title: "Commercial" },
              { columnid: "soussociete", title: "Distributeur" },
              { columnid: "fournisseur", title: "Fournisseur" },
              { columnid: "produit_code", title: "Code produit" },
              { columnid: "produit", title: "Produit" },
              { columnid: "quantite", title: "Quantite" },
              { columnid: "prix_achat_ht", title: "prix achat ht" },
              { columnid: "prix_achat_ttc", title: "prix achat ttc" },
              { columnid: "prix_ttc", title: "prix ttc" },
              { columnid: "gamme", title: "Gamme" },
              { columnid: "marque", title: "Marque" },
              { columnid: "famille", title: "Famille" },
              { columnid: "sousfamille", title: "Soufamille" },
            ],
            row: {
              style: function (sheet, row, rowidx) {
                return (
                  "text-align:center;background:" +
                  (rowidx % 2 ? "#fff" : "#f1f1f1") +
                  ";height:100px!important;"
                );
              },
            },
          };
          alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [
            file,
            response.data,
          ]);
        }

        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectSoussocietesOnly = function () {
    WS.get("soussocietes/selectSoussocietes").then(
      function (response) {
        vm.soussocietes = response.data;
        if (vm.soussocietes.length == 1) {
          vm.chargement.ch.soussociete_code = vm.soussocietes[0].code;
        }

        $scope.$apply();
      },
      function (error) {
        console.log(error);
      }
    );
  };

  vm.addPaiement = () => {
    const data = {};
    // vm.paiement.code = vm.document.code;
     vm.paiement.fournisseur_code = vm.list_factures[0].fournisseur_code;
     vm.paiement.soussociete_code = vm.list_factures[0].soussociete_code;
     vm.paiement.paiement_code = vm.searchbox.paiement_code;
    // vm.paiement.montant_rs = vm.document.montant_rs;
    vm.list_rs=[];
    _.each(vm.retenue_rs, (element, index )=> {
      vm.list_rs.push({
        code : index, 
        selected : element.selected, 
        montant_rs: element.montant_rs
      });
    });
    vm.paiement.montant_rs = vm.list_rs;
    vm.paiement.entetes = vm.list_factures;
    console.log(vm.paiement)
    vm.error_msg = '';
    vm.nb_error = 0;
    _.each(vm.paiement.cheques,(element, index) => {
      if(element.cheque && element.cheque != '' && (!element.banque_cheque || !element.num_cheque || !element.date_echeance_cheque)){
        vm.nb_error += 1;
        var ligne = index + 1
        vm.error_msg = 'Veuillez bien remplir les information du chéque N° : '+ ligne ;
      }
    });

    _.each(vm.paiement.traites,(element, index) => {       
      if(element.traite && (!element.banque_traite || !element.num_traite || !element.date_echeance_traite)){
        vm.nb_error += 1;
        var ligne = index + 1
        vm.error_msg = 'Veuillez bien remplir les information de la traite N° : ' + ligne ;
      }
    });
    
    if(vm.nb_error > 0) {
      swal(
        $translate.instant("Oups!"),
        $translate.instant(vm.error_msg),
        "warning"
      )
    }else{

      vm.montant_espece = (vm.paiement.espece) ? Number(vm.paiement.espece) : 0;
      vm.montant_cheque = _.reduce(vm.paiement.cheques, function(memo, num){ return memo + Number(num.cheque); }, 0);
      vm.montant_cheque = vm.montant_cheque ? vm.montant_cheque : 0;
      vm.montant_traite = _.reduce(vm.paiement.traites, function(memo, num){ return memo + Number(num.traite); }, 0);
      vm.montant_traite = vm.montant_traite ? vm.montant_traite : 0;
      vm.montant_total = Number(vm.montant_espece) + Number(vm.montant_cheque) + Number(vm.montant_traite);


      console.log(Number(vm.montant_espece) , vm.montant_cheque , vm.montant_traite,vm.montant_total )
        if(vm.montant_total <= 0){
          swal(
            $translate.instant("Oups!"),
            $translate.instant('Veuillez bien remplir le montant de paiement!'),
            "warning"
          )
        }else{
          WS.post("entete_achat/addPaiement", vm.paiement)
                  .then(function (response) {
                    if (response.data.success && response.data.errors.length==0) {
                      swal(
                        $translate.instant("Succès"),
                        $translate.instant("paiement ajoutée avec Succès"),
                        "success"
                      )
                        .then(function () {
                          $window.location.reload();
                        })
                        .catch(function (error) {
                          $window.location.reload();
                        });
                    }
                    if(response.data.success &&  Object.keys(response.data.errors).length>0){
                      vm.errors = response.data.errors;
                      console.log(vm.errors);
                    }
                    console.log(vm.errors);

                    vm.btn_save = true;
                    $scope.$apply();
                  })
                  .then(null, function (error) {
                    console.log(error);
                  });
        }
        
    }
    
  };

  vm.getPaiementByCode = (entete_achat_code) => {
    WS.getData("entete_achat/getPaiementByCode", { entete_achat_code })
      .then(function (response) {
        if (response.status == 200) {
          vm.sum_paiement =
            Number(response.data.espece) +
            Number(response.data.cheque) +
            Number(response.data.traite) +
            Number(response.data.retenue_source);
          $scope.$apply();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.addCheque = (index) => {
    // <div class="form-group">
    //                             <input type="text" class=""
    //                                 ng-model="vm.paiement.cheques[${index}].banque_cheque"

    //                                 placeholder="${$translate.instant('Banque Cheque')}">
    //                         </div>
      const newBlock = $compile(`
                      <div id="block_cheque_${index}">
                      <div class="flex_paiement">
                        <div class="">
                            <div class="form-group">
                                <input type="text" class=""  style="width: 95%;"
                                ng-model="vm.paiement.cheques[${index}].cheque"
                                placeholder="${$translate.instant('Montant Cheque')}">
                            </div>
                        </div>
                        <div class="">
                            
                            <div class="form-group">
                                <select ng-model="vm.paiement.cheques[${index}].banque_id" ng-change="vm.selectedBanque(${index}, vm.paiement.cheques[${index}].banque_id, 'cheque');"
                                    ng-init="vm.paiement.cheques[${index}].banque_id=''"
                                    style="width: 95%;margin-top: -3px;">
                                    <option value=""> {{'Choisir'| translate }}</option>
                                    <option ng-repeat="(k,banque) in vm.select_banques" value="{{ banque.id}}">
                                        {{banque.agence}} {{banque.banque}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="">
                            <div class="form_group">
                                <input type="text" class=""  style="width: 95%;"
                                    ng-model="vm.paiement.cheques[${index}].num_cheque"
                                    placeholder="${$translate.instant('Num Cheque')}">
                            </div>
                        </div>
                        <div class="">
                            <div class="form-group">
                                <datepicker date-format="yyyy-MM-dd"
                                    selector="form-control-date">
                                    <div class="input-group">
                                        <input class="form-control-date"  style="width: 95%;"
                                            ng-model="vm.paiement.cheques[${index}].date_echeance_cheque"
                                            placeholder="${$translate.instant('Date Echeance Cheque')}" />
                                        <span class="datepick_paiement"
                                            style="cursor: pointer"
                                            ng-click="vm.paiement.cheques[${index}].date_echeance_cheque=''">
                                            <i class="fa fa-lg fa-calendar"></i>
                                        </span>
                                    </div>
                                </datepicker>
                            </div>
                        </div>
                        <div class="text-center">
                            <i class="fa fa-minus" 
                              style="color: red;
                              font-size: 22px;
                              cursor: pointer;
                              text-align: center;
                              margin-top: 7px;"
                              ng-click="vm.removeCheque(${index})"></i>
                        </div>
                      </div>
                    <div class="clearfix"></div>
                    </div>
              `)($scope);

            $('.panel_cheques').append(newBlock);
            vm.countBlockCheques = Number(index) +1
  }


  vm.addTraite = (index) => {


    // <div class="form-group">
    //     <input type="text" class=""
    //         ng-model="vm.paiement.traites[${index}].banque_traite"

    //         placeholder="${$translate.instant('Banque Traite')}">
    // </div>
    const newBlock = $compile(`
                    <div id="block_traite_${index}">
                    <div class="flex_paiement">
                      <div class="">
                          <div class="form-group">
                              <input type="text" class=""  style="width: 95%;"
                              ng-model="vm.paiement.traites[${index}].traite"
                              placeholder="${$translate.instant('Montant Traite')}">
                          </div>
                      </div>
                      <div class="">
                          <div class="form-group">
                              <select ng-model="vm.paiement.traites[${index}].banque_id" 
                                      ng-change="vm.selectedBanque(${index}, vm.paiement.traites[${index}].banque_id, 'traite');"
                                      style="width: 95%;margin-top: -3px;">
                                  <option ng-repeat="(k,banque) in vm.select_banques" value="{{ banque.id}}">
                                      {{banque.agence}} {{banque.banque}}
                                  </option>
                              </select>
                          </div>
                      </div>
                      <div class="">
                          <div class="form_group">
                              <input type="text" class=""  style="width: 95%;"
                                  ng-model="vm.paiement.traites[${index}].num_traite"
                                  placeholder="${$translate.instant('Num Traite')}">
                          </div>
                      </div>
                      <div class="">
                          <div class="form-group">
                              <datepicker date-format="yyyy-MM-dd"
                                  selector="form-control-date">
                                  <div class="input-group">
                                      <input class="form-control-date"  style="width: 95%;"
                                          ng-model="vm.paiement.traites[${index}].date_echeance_traite"
                                          placeholder="${$translate.instant('Date Echeance traite')}" />
                                      <span class="datepick_paiement"
                                          style="cursor: pointer"
                                          ng-click="vm.paiement.traites[${index}].date_echeance_traite=''">
                                          <i class="fa fa-lg fa-calendar"></i>
                                      </span>
                                  </div>
                              </datepicker>
                          </div>
                      </div>
                      <div class="text-center">
                          <i class="fa fa-minus" 
                            style="color: red;
                            font-size: 22px;
                            cursor: pointer;
                            text-align: center;
                            margin-top: 7px;"
                            ng-click="vm.removeTraite(${index})"></i>
                      </div>
                    </div>
                  <div class="clearfix"></div>
                  </div>
            `)($scope);

          $('.panel_traites').append(newBlock);
          vm.countBlockTraites = Number(index) +1
          console.log(index, vm.countBlockTraites, vm.paiement.traites)
    }

    vm.removeCheque = function (index) {
      if (vm.paiement.cheques != undefined && vm.paiement.cheques[index]) {
          var mod = _.omit($parse("vm.paiement.cheques")($scope), index);
          $parse("vm.paiement.cheques").assign($scope, mod);   
      }
      $('#block_cheque_' + index).remove();
      //vm.recalculateSolde();
    };

    vm.removeTraite = function (index) {
      if (vm.paiement.traites != undefined && vm.paiement.traites[index]) {
          var mod = _.omit($parse("vm.paiement.traites")($scope), index);
          $parse("vm.paiement.traites").assign($scope, mod);
      }
      $('#block_traite_' + index).remove();
      //vm.recalculateSolde();
      console.log(vm.paiement.traites)
    };


    vm.recalculateSolde = () => {

      vm.solde_totale = 0;
      vm.solde_totale += vm.paiement.espece ? Number(vm.paiement.espece) : 0;
      
      angular.forEach(vm.paiement.cheques, function (value, key) {
        vm.solde_totale += value.cheque ? Number(value.cheque) : 0;
      });
      angular.forEach(vm.paiement.traites, function (value, key) {
        vm.solde_totale += value.traite ? Number(value.traite) : 0;
      });
      console.log('vm.solde_totale', vm.solde_totale)
    }

    $scope.$watch("vm.paiement.espece", function (value) {vm.recalculateSolde();});
    $scope.$watch('vm.paiement.cheques', function (newVal, oldVal) { vm.recalculateSolde(); }, true);
    $scope.$watch('vm.paiement.traites', function (newVal, oldVal) { vm.recalculateSolde(); }, true);
   



    // sudo npm install angular-async-await --save
    vm.printElement =  function(html, entete) {


      WS.getData("entete_achat/getByCode", {code : entete.code})
      .then(function (response) {
        if (response.status == 200) {
          vm.document_print = response.data;
          $scope.$apply();

          var mywindow = $window.open('', 'PRINT');

          mywindow.document.write(`<html>
                                      <head>
                                      <title> ${document.title} </title>
                                      <style>
                                          .noprint{display: none;}.text-center{text-align:center}
                                      </style>
                                  `);
          mywindow.document.write('</head><body >');
          
          mywindow.document.write(document.getElementById(html).innerHTML);
          
          mywindow.document.write('</body></html>');
          mywindow.document.close(); // necessary for IE >= 10
          mywindow.focus(); // necessary for IE >= 10*/
    
          mywindow.print();
          mywindow.close();
    
          return true
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
      
    //   console.log(vm.document);
    //    $scope.$apply();
     

      
  }

  // $scope.$watch("vm.document_print", function (value) {
  //   console.log(vm.document_print);
  //   vm.printElement('print_retenue');
  // });



  vm.selected_quantite = '';


  vm.getStatAchat = () => {
    WS.getData("entete_achat/getStatAchat", vm.filter_stat)
    .then(function (response) {
      vm.stat_prods = response.data.produits;
      vm.factures = response.data.factures;
      vm.mvt_fournisseurs = response.data.mvt_fournisseurs;

      vm.quantites = 0;
      vm.total_net_ht = 0;

      _.each(vm.stat_prods, element => {
        vm.quantites += Number(element.quantite);
        vm.total_net_ht += Number(element.total_net_ht);
      });
      $scope.$apply();
    })
    .then(null, function (error) {
      console.log(error);
    });
  }


}
export default {
  name: "achatController",
  fn: achatController,
};
