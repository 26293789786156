function recensementController($uibModal, $state, $scope, WS, $window, $compile ) {
    'ngInject';
    const vm = this;
    vm.activites = {};
    vm.new_tournee = {};
    vm.per_page = '50';
    vm.mapstunisie_latitude = '34.770981';
    vm.mapstunisie_longitude = '10.702299';
    vm.search_tournee = {};
    vm.search_tournee.per_page="50";
    vm.selected_tournees = [];
    vm.searchbox = {};
    vm.getInfoCommercial = function (code) {
        vm.infouser = _.findWhere(vm.selectusers, { code: code });
        //console.log(vm.infouser);
    }

    vm.selectUsers = function () {
        WS.getData('users/selectUsers' )
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.getLocalites = function () {
        WS.get('localites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.localites = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getDelegations = function () {
        WS.get('delegations/selectDelegations')
            .then(function (response) {
                if (response.status == 200) {
                    vm.delegations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    /** */
    vm.selectTourneesBySearch = (page = 1, per_page=50) => {
        vm.search_tournee.page = page;
        vm.search_tournee.per_page = per_page;
        WS.getData('resultats_merchs/selectTourneesBySearch', vm.search_tournee)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selected_tournees = response.data.data;

                    $scope.$apply();
                    if(vm.selected_tournees.length>0){
                         $('#map_add_tournees').empty();
                         $('#map_add_tournees').append('<div style="width: 100%; height: 550px" id="map_add_tournees_content"></div>');
                        vm.initMapsAddTournees(true);
                        console.log('reinisialisation : initMapsAddTournees')
                    }
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.updateCouleurUser = (user) => {
        WS.post('users/updateCouleurUser', user )
        .then(function (response) {
            if (response.status == 200) {
                //vm.selectusers = response.data;
                swal(
                    'Succès',
                    'Couleur modifiée avec succès',
                    'success'
                );
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }

    vm.removeTournee = (value, key) => {
       vm.map.eachLayer(function (layer) {
            if(value.id == layer.options.id){
                vm.map.removeLayer(layer)
            }
           //
        }); 
    }

    vm.clearLayers = () => {
        vm.map.eachLayer(function (layer) {
            vm.map.removeLayer(layer)
        }); 
    }

    /**
     * Initialisation de maps
     */
    
    vm.initMapsAddTournees = (force_init = false) => {
        // if(force_init){
           
        // }
        if (_.isEmpty($('#map_add_tournees_content')[0])) {
            vm.center_latitude = vm.mapstunisie_latitude;
            vm.center_longitude = vm.mapstunisie_longitude;
            //  $('#map_add_tournees').empty();
            //  $('#map_add_tournees').append('<div style="width: 100%; height: 550px" id="map_add_tournees_content"></div>');
    
            vm.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
            vm.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
            vm.osm = L.tileLayer(vm.osmUrl, {
                maxZoom: 18,
                attribution: vm.osmAttrib
            });
            //console.log('from maps : ', vm.getpays)
            vm.map = new L.Map('map_add_tournees_content', {
                center: new L.LatLng(vm.center_latitude, vm.center_longitude),
                zoom: 11,
                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: 'topright'
                }
            });
    
            vm.osm.addTo(vm.map);

            /**
             * add polygon and panel of draw
             */
            vm.raduis = 0;
            vm.layer = {};
            vm.geojsonMarkerOptions = {
                radius: vm.raduis
            };
            //vm.drawnItems = null;

            // if (vm.layer) {
            //     if (vm.raduis) {
            //         vm.paramsdrawitem = {
            //             pointToLayer: function (feature, latlng) {
            //                 return L.circle(latlng, vm.geojsonMarkerOptions);
            //             }
            //         }
            //     } else {
            //         vm.paramsdrawitem = {};
            //     }
            // }

            /** params draw item */
            vm.paramsdrawitem = {};
            if(Object.keys(vm.layer).length === 0){
                vm.drawnItems = L.geoJson(null, vm.paramsdrawitem).addTo(vm.map);

            }else{
                vm.drawnItems = L.geoJson(JSON.parse(vm.layer), vm.paramsdrawitem).popup('Hi There!').tooltip('Hi There!').addTo(vm.map);

            }
            if(vm.selected_tournees.length > 0 && force_init==true){
               // console.log('redraw all layers : ', vm.selected_tournees)

                _.each(vm.selected_tournees, (element, index) => {
                    vm.paramsdrawitem.id = element.id;
                    vm.paramsdrawitem.code = element.code;
                    vm.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                    vm.paramsdrawitem.onEachFeature =  function (feature, layer) {
                        layer.bindPopup('<pre>'+ element.id + ' - '+ element.user +' - '+ element.date_debut + ' - '+ element.date_fin +'</pre>');
                        layer._leaflet_id = element.id;
                    }
                    vm.current_layer = JSON.parse(element.layer);
                    vm.current_layer._leaflet_id = element.id;
                    vm.drawnItems = L.geoJson(vm.current_layer, vm.paramsdrawitem, element.type_layer, element.id).addTo(vm.map);
                    // vm.drawnItems.eachLayer(function (layer) {
                    //     layer._leaflet_id =  element.id;
                    // });
                    //console.log('Polygon : ', element.id, vm.drawnItems)
                })
            }

            vm.map.addControl(new L.Control.Draw({
                edit: force_init == false ? { featureGroup: vm.drawnItems } : false,
               // edit:  { featureGroup: vm.drawnItems } ,
                draw: {
                    polyline: false,
                    polygon: {
                        allowIntersection: false, // Restricts shapes to simple polygons
                        showArea: true,
                    },
                    rectangle: true,
                    marker: false,
                    circlemarker: false,
                    circle: false,
                }
            }));

            vm.map.on(L.Draw.Event.CREATED, function (e) {
                console.log('------- created ------------');

                vm.type = e.layerType
                vm.layer = e.layer;
                // layer.editing.enable();

                vm.drawnItems.addLayer(vm.layer);
                if (type == 'circle') {
                    vm.raduis = vm.layer.getRadius()
                } else {
                    vm.raduis = '';
                }
                vm.shape = vm.layer.toGeoJSON()
                vm.shape_for_db = vm.shape;
                //var shape_for_db = JSON.stringify(shape);

                vm.response_layer = {
                    raduis: vm.raduis,
                    type: vm.type,
                    layer: vm.shape_for_db,
                    coordinates : vm.shape_for_db.geometry.coordinates,
                    properties : vm.shape_for_db.properties
                };
                console.log(vm.response_layer);

                console.log('-------------- delete shape ---------------');

                /**
                 * add form to create new tournee
                 */
                vm.modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'add_new_tournee_form',
                    size: 'md',
                    backdrop: false,
                    windowClass : 'z-index-sec-modal',
                    controller: function($scope, $translate) {
                        $scope.message = $translate.instant("Ajouter tournee : ");
                        $scope.layer = vm.response_layer;
                        $scope.new_tournee = {};
                        $scope.selectusers = vm.selectusers;
                        $scope.storeTournee = function() {
                            var data = {
                                code : $scope.new_tournee.code,
                                user_code : $scope.new_tournee.user_code,
                                date_debut : $scope.new_tournee.date_debut,
                                date_fin : $scope.new_tournee.date_fin,
                                couleur : $scope.new_tournee.couleur,

                                raduis: $scope.layer.raduis,
                                type: $scope.layer.type,
                                layer: $scope.layer.layer,
                                coordinates : $scope.layer.coordinates,
                                properties : $scope.layer.properties
                            }

                            console.log('------------------------ fin resultat -----------------------');
                            console.log(data);
                            console.log('------------------------ fin resultat -----------------------');
                                WS.post('resultats_merchs/storeTournee', data)
                                    .then(function (response) {
                                        if (response.status == 200) {
                                            
                                            vm.modalInstance.close();
                                            swal(
                                                'Succès',
                                                'Secteur crée avec succé',
                                                'success'
                                            );
                                            $scope.$apply();
                                        } else { }
                                    })
                                    .then(null, function (error) {
                                        console.log(error);
                                    });
                        }

                        $scope.closeModal = () => {
                            vm.map.removeLayer(vm.layer);
                            vm.modalInstance.close();
                        }
                    }
                });


                console.log('-------------- deleted shape ---------------');

                // WS.post('depots/updateLayers', data)
                //     .then(function (response) {
                //         if (response.data.success == true) {
                //             swal(
                //                 'Succès',
                //                 'Secteur crée avec succé',
                //                 'success'
                //             );
                //             // .then(function () {
                //             //     $window.location.reload();
                //             // });

                //         } else if (response.data.success == false) {
                //             swal('Error', 'Oups ', 'error');
                //         }
                //     })
                //     .then(null, function (error) {
                //         console.log(error);
                //     });
                // layer.on("edit", function (event) {
                //     var content = "";
                //     if (type == "circle") {
                //         content = 'Circle with : <ul><li> Radius : ' + layer.getRadius() + '</li>' +
                //             '<li> Center : ' + layer.getLatLng() + ' </li></ul>';
                //     }
                //     else if (type === "polygon") {
                //         content = ' Polygone with : ' + layer.getLatLngs()[0].length + ' nodes';
                //     }
                //     layer.bindPopup(content).openPopup();
                // });
            });


            vm.map.on(L.Draw.Event.EDITED, function (e) {
                console.log('-------edited------------');
                var layers = e.layers;
                console.log(layers);
                layers.eachLayer(function (layer) {
                    // Do whatever else you need to. (save to db; add to map etc)
                    // console.log('geometry: ', layer.toGeoJSON().geometry);
                    // console.log('layer.getLatLngs(): ', layer.getLatLngs());

                    //remove previous layer
                    // var area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                    // console.log('area: ', area);
                    // map.addLayer(layer);

                    //issue: Finish drawing process with editable feature
                    var newLayer = L.GeoJSON.geometryToLayer(layer.toGeoJSON().geometry);
                    //console.log('newLayer', newLayer);
                    //newLayer.editing.enable();
                    vm.map.addLayer(newLayer);

                    var layer_type = layer.toGeoJSON().geometry.type;
                    if (layer_type == 'circle') {
                        var raduis = layer.getRadius()
                    } else {
                        var raduis = '';
                    }
                    var shape = newLayer.toGeoJSON()
                    var shape_for_db = shape;
                    // var shape_for_db = JSON.stringify(shape);
                    //console.log(layer_type, shape_for_db);
                    var data = {
                        raduis: raduis,
                        type: layer_type,
                        layer: shape_for_db
                    };
                    console.log(data);
                    // WS.post('depots/updateLayers', data)
                    //     .then(function (response) {
                    //         if (response.data.success == true) {

                    //             swal(
                    //                 'Succès',
                    //                 'Secteur crée avec succé',
                    //                 'success'
                    //             );
                    //             // .then(function () {
                    //             //     $window.location.reload();
                    //             // });
                    //             $scope.$apply();

                    //         } else if (response.data.success == false) {
                    //             swal('Error', 'Oups ', 'error');
                    //         }
                    //     })
                    //     .then(null, function (error) {
                    //         console.log(error);
                    //     });
                });
            });

            //draw:deleted	

            // vm.map.on(L.Draw.Event.DELETED, function () {

            //     console.log('delete polygonn');
            //     // WS.post('depots/updateLayers', { depot_code: depot_code })
            //     //     .then(function (response) {
            //     //         if (response.data.success == true) {
            //     //             swal(
            //     //                 'Succès',
            //     //                 'Secteur supprimé avec succé',
            //     //                 'success'
            //     //             );
                            

            //     //         } else if (response.data.success == false) {
            //     //             swal('Error', 'Oups ', 'error');
            //     //         }
            //     //     })
            //     //     .then(null, function (error) {
            //     //         console.log(error);
            //     //     });
            // });

            setTimeout(function () {
                vm.map.invalidateSize();
                console.log('initialized');
            }, 2000);
        }else{
            console.log('maps not created!!')
        }
    }

    vm.showMaps = function (user_code, depot_code) {

        WS.getData('users/infoMaps', { user_code, depot_code })
            .then(function (response) {

                vm.maps_depot = response.data.depot;
                vm.maps_user = response.data.user;
                vm.maps_pays = response.data.pays;

                vm.nbr_client_total = response.data.nbr_client_total.total;


                vm.list_client_hors_tourne = response.data.client_hors_tourne;
                vm.list_client_tourne = response.data.client_tourne;

                vm.client_hors_tourne = response.data.client_hors_tourne.length;
                vm.client_tourne = response.data.client_tourne.length;
                //vm.nbr_client_tourne_jour = response.data.nbr_client_tourne_jour; //array response

                var center_latitude = (vm.maps_user.latitude) ? vm.maps_user.latitude : vm.maps_pays.latitude;
                var center_longitude = (vm.maps_user.longitude) ? vm.maps_user.longitude : vm.maps_pays.longitude;
                $('#container_maps').empty();
                $('#container_maps').append('<div style="width: 100%; height: 550px" id="mapContainerCmds"></div>');

                var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                var osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                var osm = L.tileLayer(osmUrl, {
                    maxZoom: 18,
                    attribution: osmAttrib
                });
                //console.log('from maps : ', vm.getpays)
                var map = new L.Map('mapContainerCmds', {
                    center: new L.LatLng(center_latitude, center_longitude),
                    zoom: 11,
                    fullscreenControl: true,
                    fullscreenControlOptions: {
                        position: 'topright'
                    }
                });

                osm.addTo(map);

                
                /**
                 * add polygon and panel of draw
                 */
                var geojsonMarkerOptions = {
                    radius: vm.maps_depot.raduis
                };
                if (vm.maps_depot.layer) {
                    if (vm.maps_depot.raduis) {
                        var paramsdrawitem = {
                            pointToLayer: function (feature, latlng) {
                                return L.circle(latlng, geojsonMarkerOptions);
                            }
                        }
                    } else {
                        var paramsdrawitem = {};
                    }
                }
                var drawnItems = L.geoJson(JSON.parse(vm.maps_depot.layer), paramsdrawitem).addTo(map);

                map.addControl(new L.Control.Draw({
                    edit: {
                        featureGroup: drawnItems
                    },
                    draw: {
                        polyline: true,
                        polygon: {
                            allowIntersection: false, // Restricts shapes to simple polygons
                            showArea: true,
                        },
                        rectangle: true,
                        marker: true,
                        circlemarker: false,
                        circle: false,
                    }
                }));

                map.on(L.Draw.Event.CREATED, function (e) {
                    var type = e.layerType
                        , layer = e.layer;
                    // layer.editing.enable();

                    drawnItems.addLayer(layer);
                    if (type == 'circle') {
                        var raduis = layer.getRadius()
                    } else {
                        var raduis = '';
                    }
                    var shape = layer.toGeoJSON()
                    var shape_for_db = JSON.stringify(shape);

                    var data = {
                        raduis: raduis,
                        type: type,
                        layer: shape_for_db,
                        depot_code: depot_code
                    };

                    WS.post('depots/updateLayers', data)
                        .then(function (response) {
                            if (response.data.success == true) {
                                swal(
                                    'Succès',
                                    'Secteur crée avec succé',
                                    'success'
                                );
                                // .then(function () {
                                //     $window.location.reload();
                                // });

                            } else if (response.data.success == false) {
                                swal('Error', 'Oups ', 'error');
                            }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                    // layer.on("edit", function (event) {
                    //     var content = "";
                    //     if (type == "circle") {
                    //         content = 'Circle with : <ul><li> Radius : ' + layer.getRadius() + '</li>' +
                    //             '<li> Center : ' + layer.getLatLng() + ' </li></ul>';
                    //     }
                    //     else if (type === "polygon") {
                    //         content = ' Polygone with : ' + layer.getLatLngs()[0].length + ' nodes';
                    //     }
                    //     layer.bindPopup(content).openPopup();
                    // });
                });


                map.on(L.Draw.Event.EDITED, function (e) {
                    console.log('-------edited------------');
                    var layers = e.layers;
                    console.log(layers);
                    layers.eachLayer(function (layer) {
                        // Do whatever else you need to. (save to db; add to map etc)
                        // console.log('geometry: ', layer.toGeoJSON().geometry);
                        // console.log('layer.getLatLngs(): ', layer.getLatLngs());

                        //remove previous layer
                        // var area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                        // console.log('area: ', area);
                        // map.addLayer(layer);

                        //issue: Finish drawing process with editable feature
                        var newLayer = L.GeoJSON.geometryToLayer(layer.toGeoJSON().geometry);
                        //console.log('newLayer', newLayer);
                        //newLayer.editing.enable();
                        map.addLayer(newLayer);

                        var layer_type = layer.toGeoJSON().geometry.type;
                        if (layer_type == 'circle') {
                            var raduis = layer.getRadius()
                        } else {
                            var raduis = '';
                        }
                        var shape = newLayer.toGeoJSON()
                        var shape_for_db = JSON.stringify(shape);
                        //console.log(layer_type, shape_for_db);
                        var data = {
                            raduis: raduis,
                            type: layer_type,
                            layer: shape_for_db,
                            depot_code: depot_code
                        };
                        //console.log(data);
                        WS.post('depots/updateLayers', data)
                            .then(function (response) {
                                if (response.data.success == true) {

                                    swal(
                                        'Succès',
                                        'Secteur crée avec succé',
                                        'success'
                                    );
                                    // .then(function () {
                                    //     $window.location.reload();
                                    // });
                                    $scope.$apply();

                                } else if (response.data.success == false) {
                                    swal('Error', 'Oups ', 'error');
                                }
                            })
                            .then(null, function (error) {
                                console.log(error);
                            });
                    });
                });

                //draw:deleted	

                map.on(L.Draw.Event.DELETED, function () {

                    WS.post('depots/updateLayers', { depot_code: depot_code })
                        .then(function (response) {
                            if (response.data.success == true) {
                                swal(
                                    'Succès',
                                    'Secteur supprimé avec succé',
                                    'success'
                                );
                                // .then(function () {
                                //     $window.location.reload();
                                // });;

                            } else if (response.data.success == false) {
                                swal('Error', 'Oups ', 'error');
                            }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                });

                $scope.$apply();
                setTimeout(function () {
                    map.invalidateSize();
                    console.log('initialized');
                }, 2000);
                //_.defer(map.invalidateSize.bind(map));
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    
    vm.selectRsFamilles = () => {
        WS.getData('resultats_merchs/selectRsFamilles' )
        .then(function (response) {
            if (response.status == 200) {
               vm.rs_familles = response.data;
               $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }

    vm.selectRsMarques = () => {
        WS.getData('resultats_merchs/selectRsMarques' )
        .then(function (response) {
            if (response.status == 200) {
               vm.rs_marques = response.data;
               $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }

    vm.selectActivites = () => {
        WS.get('activites/selectActivites')
            .then(function (response) {
                vm.activites = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    //
    /**
     * Get traveaux merchandiseurs
     */
    vm.getTraveauxMerch = function (page = 1, per_page = 50) {

        var data = {
            page, per_page,
            famille_code : vm.searchbox.famille_code,
            marque_code : vm.searchbox.marque_code,
            delegation_code : vm.searchbox.delegation_code,
            localite_code : vm.searchbox.localite_code,
            activite_code : vm.searchbox.activite_code
        }
        WS.getData('resultats_merchs/getTraveauxMerch', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.traveaux_rs = response.data.clients.data;
                    vm.rs_merchs_marques_familles = response.data.rs_merchs_marques_familles;
                    //vm.clients = response.data.clients.data;
                    vm.current_page = response.data.clients.current_page;
                    vm.previous = vm.current_page - 1;
                    vm.next = vm.current_page + 1;
                    vm.from = response.data.clients.form;
                    vm.last_page = response.data.clients.last_page;
                    vm.next_page_url = response.data.clients.next_page_url;
                    vm.per_page = response.data.clients.per_page;
                    vm.prev_page_url = response.data.clients.prev_page_url;
                    vm.to = response.data.clients.to;
                    vm.total = response.data.clients.total;
                    vm.paginate = [];
                    for (var i = 1; i <= vm.last_page; i++) {
                        vm.paginate.push(i);
                    }

                    

                    /**
                     * MAPS
                     */
                    vm.selectmapsclients = angular.copy(vm.traveaux_rs);
                    vm.centerroutes = angular.copy(response.data.center.latitude + ', ' + response.data.center.longitude);
                    vm.zoomRouting = 7;
                    
                    $('#container-Client').empty();
                    $('#container-Client').html('<div style="width: 100%; height: 600px" id="mapContainerClients"></div>');

                    var mymap = L.map('mapContainerClients').setView([response.data.center.latitude, response.data.center.longitude], vm.zoomRouting);


                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                        foo: 'bar',
                        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                    })
                        .addTo(mymap);


                    vm.selectmapsclients.forEach(function (element, index) {
                        // Creates a red marker with the coffee icon
                        var redMarker = L.ExtraMarkers.icon({
                            icon: 'fa-home',
                            markerColor: (parseFloat(element.encours)) < 0 ? 'red' : 'blue',
                            shape: 'circle', //'circle', 'square', 'star', or 'penta'
                            prefix: 'fa'
                        });

                        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                        var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                              <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                <b> | </b>
                                                <a ui-sref="app.clients.historiques({code : ${element.code}})" target="_blank">
                                                  Historique
                                                </a>
                                              </p>
                                              <b>Client</b> : ${element.client}</a></br>
                                              <b>Code</b> : ${element.code}</a></br>
                                              <b>Adresse</b> : ${element.adresse_facturation}</br>
                                              <b>Activite</b> : ${element.activite}</br>
                                              
                                            </div>
                                        `)($scope);
                        var popup = L.popup({
                            maxWidth: 600,
                            minWidth: 200
                        }).setContent(info[0])
                        marker.bindPopup(popup);

                    });
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.selectFamillesByMarque = () => {
        var data = {
            marque_code : vm.searchbox.marque_code
        }
        WS.getData('resultats_merchs/selectFamillesByMarque', data )
        .then(function (response) {
            if (response.status == 200) {
               vm.rs_familles_marque = response.data;
               $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }

    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }
    
    vm.getDetailsByClientFamille = function (tr) {
        var data = {
            client_code : tr.code

        }
        WS.getData('resultats_merchs/getDetailsByClientFamille', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rs_details = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
}
export default {
    name: 'recensementController',
    fn: recensementController
};